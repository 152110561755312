import React from 'react';
import {
  Avatar,
  Typography,
  Box,
  Divider,
  useMediaQuery,
  useTheme
} from "@mui/material";
import TwitchIcon from "./icons/TwitchIcon";
import GumroadIcon from "./icons/GumroadIcon";
import DiscordIcon from "./icons/DiscordIcon";
import GithubIcon from "./icons/GithubIcon";
import KofiIcon from "./icons/KofiIcon";
import SocialsIconButton from "./SocialsIconButton";
import { Link } from 'react-router-dom';
import SoundcloudIcon from "./icons/SoundcloudIcon";

interface AboutProps {
  // Define your props here
}

const About: React.FC<AboutProps> = (props) => {
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up('sm'));

  const dividerOrientation = largeView ? "vertical" : "horizontal";
  const flexDirection = largeView ? "row" : "column";

  return (
    <>
      <Box sx={{ mt: 1 }}>
      <Link to={"/comms"} style={{ textDecoration: 'none' }}>
        <Avatar alt="BASIC" src="/img/basic_avatar.png" sx={{ width: 200, height: 200 }}/>
      </Link>
      </Box>
      <Typography variant="h1" sx={{m:2, mt: 3}}>
        BASIC
      </Typography>
      <Typography variant="h6" align="center">
        Software Dev | 3D Designer | VRDJ
      </Typography>
      <Box sx={{ border: 1, borderRadius: 5, borderColor: 'background.paper', p: 2, m: 2, backgroundColor: 'background.paper' }}>
      {/*<Card sx={{m: 2}}>*/}
      {/*  <CardContent>*/}
      {/*    <Typography variant="h5" align="center" gutterBottom>*/}
      {/*      Socials*/}
      {/*    </Typography>*/}
          <Box sx={{ display: 'flex', gap: '5px', flexDirection, alignItems: 'center' }}>
            <Box>
              <SocialsIconButton
                href="https://x.com/BASIC_BYTE"
                name="twitter">
                <img src="/img/twitter.png" width={40} height={40} alt="X"/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                href="https://twitch.com/BASIC_BIT"
                name="twitch">
                <TwitchIcon/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                href="https://basicbit.gumroad.com/"
                name="gumroad">
                <GumroadIcon/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                href="https://discord.gg/PYKTaJ5Ett"
                name="discord">
                <DiscordIcon/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                href="https://github.com/BASIC-BIT"
                name="github">
                <GithubIcon/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                href="https://ko-fi.com/basicbit"
                name="kofi">
                <KofiIcon/>
              </SocialsIconButton>
            </Box>
            <Divider orientation={dividerOrientation} flexItem sx={{m: 1}}/>
            <Box>
              <SocialsIconButton
                  href="https://soundcloud.com/basic-bit"
                  name="soundcloud">
                <SoundcloudIcon/>
              </SocialsIconButton>
            </Box>
          </Box>
      {/*  </CardContent>*/}
      {/*</Card>*/}
      </Box>
    </>
  )
    ;
};

export default About;